import {
    configure,
    observable,
    computed,
    when,
    reaction,
    makeObservable,
} from 'mobx';
import React from 'react';

import http from 'http.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';

import autobind from 'common/decorators/autobind.js';
import {
    PROMOTION_DEFAULT,
    SPECIALTY_ORTHOPAEDIC_SURGEON,
    PROMOTION_SECOND_OPINION,
    PROMOTION_TREATMENT_DECISION_SUPPORT,
} from 'core/promotions.js';
import Store from 'core/stores/Store.js';
import {REFERRALS, SITE} from 'core/constants.js';
import {flatten} from 'core/utils.js';

// Throws if observable is changed outside of action
configure({enforceActions: 'observed', safeDescriptors: false});

export const JOINT_PAIN_ANSWER_YES = 'Yes';
export const JOINT_PAIN_ANSWER_NO = 'No';

export const MODAL_TYPE_PROMOTION = 'modal_promotion';
export const MODAL_TYPE_BANNERADS = 'modal_bannerads';

export default class PromotionsStore extends Store {
    constructor(rootStore) {
        super();

        this.rootStore = rootStore;

        makeObservable(this, {
            displayModal: observable,
            promotions: observable,
            specialties: observable,
            specialInterests: observable,
            selectedSpecialty: observable,
            hasBannerAdsOnMultipleSpecialties: observable,
            practices: observable,
            bannerAds: observable,
            isEnhanced: observable,
            isSpecialist: observable,
            selectedAnswers: observable,
            formNotification: observable,
            formFullName: observable,
            formPhoneNumber: observable,
            hasNoPromotions: observable,
            bannerAdsFetched: observable,
            modalType: observable,

            loading: computed,
            selectedPromotion: computed,
            ctaCopy: computed,
            hidePromotionCTA: computed,
            hideSorryMessage: computed,
            displayPromotedProfiles: computed,
            allAnswersCorrect: computed,
            allQuestionsAnswered: computed,
            relevantPromotions: computed,
            promotedHealthFundSelected: computed,
            relevantHealthFundSelected: computed,
            hasSpecialtyQuestion: computed,
            hasSeenASpecialistQuestion: computed,
            chosenSpecialty: computed,
            hasSeenASpecialist: computed,
            specialtyBannerAds: computed,
            questions: computed,
            jointPainAnswer: computed,
        });

        when(
            () => this.specialties.length >= 1 || this.specialInterests >= 1,
            () => this.fetchBannerAds(),
        );

        reaction(
            () => this.promotedHealthFundSelected,
            () =>
                this.updateStore({
                    selectedAnswers: [],
                }),
        );

        when(
            () => this.healthFundsFetched && this.bannerAdsFetched,
            () => this.setHasNoPromotions(),
        );
    }

    displayModal = false;
    promotions = [];

    specialties = [];
    specialInterests = [];
    selectedSpecialty = null;
    hasBannerAdsOnMultipleSpecialties = false;
    practices = [];
    bannerAds = [];

    isEnhanced = false;
    isSpecialist = false;

    selectedAnswers = [];

    // form state
    formNotification = null;
    formFullName = '';
    formPhoneNumber = '';

    hasNoPromotions = false;

    // apis fetched
    bannerAdsFetched = false;

    modalType = MODAL_TYPE_BANNERADS;

    get healthFundsFetched() {
        return !!this.rootStore.healthFundStore.healthFunds.length;
    }

    get loading() {
        return !this.healthFundsFetched || !this.bannerAdsFetched;
    }

    get selectedPromotion() {
        if (
            this.modalType === MODAL_TYPE_PROMOTION &&
            this.promotedHealthFundSelected &&
            this.allAnswersCorrect &&
            this.allQuestionsAnswered
        ) {
            return this.getPromotion();
        }
        return null;
    }

    get ctaCopy() {
        return 'Choose a specialty';
    }

    get titleCopy() {
        if (this.modalType === MODAL_TYPE_PROMOTION) {
            return 'Get a free personalised medical report on treatment options';
        }
        return this.ctaCopy;
    }

    @autobind
    getPromotion() {
        const relevantPromotion = this.relevantPromotions[0];
        if (relevantPromotion) {
            switch (relevantPromotion.name) {
                case PROMOTION_TREATMENT_DECISION_SUPPORT:
                    return {
                        name: PROMOTION_TREATMENT_DECISION_SUPPORT,
                        id: relevantPromotion.id,
                        title: 'About Treatment Decision Support',
                        phoneNumber: '(02) 4058 2746',
                    };

                case PROMOTION_SECOND_OPINION:
                    return {
                        name: PROMOTION_SECOND_OPINION,
                        id: relevantPromotion.id,
                        title:
                            'offers its members a 2nd opinion by a medical specialist at no cost. The service is provided by Best Doctors Australia. By submitting your details below, you consent for HealthShare to provide these to Best Doctors Australia who will contact you with further information about the ',
                        phoneNumber: '(02) 8294 7956',
                    };

                default:
                    return {
                        name: PROMOTION_DEFAULT,
                        id: null,
                        title: '',
                        phoneNumber: '',
                    };
            }
        }
    }

    @autobind
    setHasNoPromotions() {
        // True for the following conditions:
        //   1. No promotions available
        //   2. No health fund or the "I don't have a health fund" option has been selected
        //   3. Has a selected health fund that is not participating in any available promotions

        let hasNoPromotions = false;
        if (
            !this.promotions?.length ||
            !this.rootStore.healthFundStore?.healthFund?.id ||
            !this.hasPromotionsAvailable() ||
            (this.rootStore.healthFundStore?.healthFund?.id &&
                !this.promotedHealthFundSelected)
        ) {
            hasNoPromotions = true;
        }
        this.updateStore({
            hasNoPromotions,
        });
    }

    @autobind
    hasPromotionsAvailable() {
        const availablePromotion = this.promotions?.find((promotion) => {
            return promotion.healthFunds.find((promotedFund) => {
                return this.rootStore.healthFundStore.healthFunds.find(
                    (fund) => fund.id === promotedFund,
                );
            });
        });
        return !!availablePromotion;
    }

    get hidePromotionCTA() {
        // Hide CTA for the following conditions:
        //   1. Profile is enhanced
        //   2. Health funds and banner ads are loading
        //   3. Profile is not a specialist OR doesn't have specialty banner ads

        return (
            this.isEnhanced ||
            this.loading ||
            (!this.hasBannerAdsOnMultipleSpecialties &&
                (!this.specialtyBannerAds.length ||
                    !this.specialties.find(
                        (specialty) => specialty.isSpecialist,
                    )))
        );
    }

    get hideSorryMessage() {
        // Hide sorry message if there are
        // no promotions and banner ads for the specialty are present
        // or the modal type is banner ads and no specialty is selected
        return (
            (this.hasNoPromotions && this.specialtyBannerAds.length > 0) ||
            (this.modalType === MODAL_TYPE_BANNERADS &&
                !this.selectedSpecialty)
        );
    }

    get displayPromotedProfiles() {
        if (
            !this.rootStore.healthFundStore?.healthFund?.id ||
            this.modalType === MODAL_TYPE_BANNERADS
        ) {
            return true;
        }
        if (!this.allQuestionsAnswered || !this.displayModal) {
            return false;
        }
        if (!this.relevantHealthFundSelected && !this.selectedPromotion) {
            return true;
        } else if (!this.allAnswersCorrect) {
            return true;
        }

        return false;
    }

    get allAnswersCorrect() {
        const filterIncorrectAnswers = this.selectedAnswers.filter(
            (answer) => {
                return !answer.isCorrect;
            },
        );
        return filterIncorrectAnswers.length === 0;
    }

    get allQuestionsAnswered() {
        return this.selectedAnswers.length >= this.questions.length;
    }

    @autobind
    promotionIsRelevant(promotion) {
        const includesSelectedHealthFund = promotion.healthFunds?.includes(
            this.rootStore.healthFundStore?.healthFund?.id,
        );
        const includesSelectedSpecialty = promotion.specialties?.includes(
            this.selectedSpecialty,
        );
        return (
            includesSelectedHealthFund &&
            (!this.selectedSpecialty || includesSelectedSpecialty)
        );
    }

    /**
     * Returns an array of promotions that are relevant
     * to the currently selected health fund
     */
    get relevantPromotions() {
        if (
            !this.rootStore.healthFundStore?.healthFund?.id ||
            !this.promotions?.length
        ) {
            return [];
        }
        return this.promotions
            .filter((promotion) => this.promotionIsRelevant(promotion))
            .sort((a, b) => a.order - b.order);
    }

    // Create union of health funds for every promotion in the store
    @autobind
    combineFunds(promotions) {
        return [
            ...new Set(
                flatten(
                    promotions?.map((promotion) =>
                        promotion.healthFunds.slice(),
                    ) || [],
                ),
            ),
        ];
    }

    /**
     * Returns true if the selected health fund is
     * associated with a Teledoc promotion
     */
    get promotedHealthFundSelected() {
        return !!this.combineFunds(this.promotions).find(
            (fund) => fund === this.rootStore.healthFundStore?.healthFund?.id,
        );
    }

    /**
     * Returns true if the selected health fund is
     * associated with a Teledoc promotion or an associated specialty
     */
    get relevantHealthFundSelected() {
        return !!this.combineFunds(this.relevantPromotions).find(
            (fund) => fund === this.rootStore.healthFundStore?.healthFund?.id,
        );
    }

    get teledocPromotionAvailableOnSelectedFund() {
        const {healthFund} = this.rootStore.healthFundStore;
        if (!healthFund?.id || !this.promotions?.length) {
            return false;
        }
        return !!this.promotions.find((promotion) => {
            return (
                promotion.name === PROMOTION_TREATMENT_DECISION_SUPPORT &&
                !!promotion.healthFunds.find((fund) => fund === healthFund.id)
            );
        });
    }

    /**
     * Returns if a promotions has a specialty question.
     */
    get hasSpecialtyQuestion() {
        return !!this.questions?.find(
            (question) =>
                question.question === 'Which specialty are you interested in?',
        );
    }

    /**
     * Returns `true` if a attendance question have been displayed, `false` if not.
     */
    get hasSeenASpecialistQuestion() {
        return !!this.questions?.find(
            (question) =>
                question.question ===
                'Have you recently had a consultation, received treatment or a treatment recommendation from a specialist?',
        );
    }

    /**
     * Returns a string value of specialty the form is targeted.
     */
    get chosenSpecialty() {
        if (
            this.selectedPromotion?.name ===
            PROMOTION_TREATMENT_DECISION_SUPPORT
        ) {
            return 'Orthopaedic Surgeon';
        } else if (
            this.hasSpecialtyQuestion &&
            this.selectedAnswers.length > 0
        ) {
            // TODO: Assumes that specialty question is first in order
            return this.selectedAnswers[0].answer;
        }
        return this.specialties[0].name;
    }

    get hasSeenASpecialist() {
        if (this.hasSeenASpecialistQuestion) {
            // This logic assumes that if a question has been displayed,
            // the only value that can be submitted is true
            return true;
        }
        return false;
    }

    @autobind
    resetStore() {
        this.updateStore({
            formFullName: '',
            formNotification: null,
            formPhoneNumber: '',
            selectedAnswers: [],
        });
    }

    @autobind
    closeModal(eventLabel) {
        this.updateStore({
            displayModal: false,
        });

        this.resetStore();
    }

    @autobind
    showModal(modalType) {
        this.updateStore({
            displayModal: true,
            modalType,
        });
    }

    @autobind
    selectAnswer(answer, questionIndex) {
        // remove previously selected answers for following questions
        const selectedAnswers = this.selectedAnswers.slice(0, questionIndex);
        const acceptedAnswer = this.questions[questionIndex].acceptedAnswer;
        const isCorrect = acceptedAnswer === answer.name;

        // return isCorrect as true if no answer is defined for question
        selectedAnswers[questionIndex] = {
            answer: answer.name,
            isCorrect: acceptedAnswer ? isCorrect : true,
        };

        if (this.questions[questionIndex].specialtyQuestion) {
            this.updateStore({
                selectedSpecialty: answer.id,
            });
        }

        this.updateStore({selectedAnswers});
    }

    get specialtyBannerAds() {
        return (
            this.specialties?.find(
                (specialty) => specialty.id === this.selectedSpecialty,
            )?.bannerAds || []
        );
    }

    get questions() {
        const questions = [];
        if (this.specialties.length > 1) {
            const {
                isProUser,
                isReferrals,
                isClient,
            } = this.rootStore.paramStore;
            const proUser = isProUser || isReferrals || isClient;
            const answers = this.specialties.map((specialty) => {
                if (proUser) {
                    specialty.name = specialty.displayName;
                }
                return specialty;
            });
            questions.push({
                question: 'Which specialty are you interested in?',
                answers,
                acceptedAnswer: '',
                specialtyQuestion: true,
            });
        }
        if (
            this.selectedSpecialty !== SPECIALTY_ORTHOPAEDIC_SURGEON &&
            this.promotedHealthFundSelected &&
            this.modalType === MODAL_TYPE_PROMOTION
        ) {
            questions.push({
                question:
                    'Have you recently had a consultation, received treatment or a treatment recommendation from a specialist?',
                answers: [
                    {id: 0, name: 'Yes'},
                    {id: 1, name: 'No'},
                ],
                acceptedAnswer: 'Yes',
            });
        }
        if (
            this.selectedSpecialty === SPECIALTY_ORTHOPAEDIC_SURGEON &&
            this.promotedHealthFundSelected &&
            this.modalType === MODAL_TYPE_PROMOTION
        ) {
            questions.push({
                question:
                    'Do you have hip, spine (back), shoulder or knee pain?',
                answers: [
                    {id: 0, name: JOINT_PAIN_ANSWER_YES},
                    {
                        id: 1,
                        name: JOINT_PAIN_ANSWER_NO,
                    },
                ],
                acceptedAnswer: JOINT_PAIN_ANSWER_YES,
            });
        }
        return questions;
    }

    get jointPainAnswer() {
        return this.selectedAnswers.find(
            (a) =>
                a.answer === JOINT_PAIN_ANSWER_YES ||
                a.answer === JOINT_PAIN_ANSWER_NO,
        );
    }

    @autobind
    async fetchBannerAds() {
        if (!this.practices.length) {
            // Prevent API call if
            //   1. practitioner has no practices since banner ads API will not have a locality id
            return;
        }
        let bannerAds = [];
        try {
            const urlParams = {
                interest: this.specialInterests.map((interest) => interest.id),
                locality: this.practices[0].localityId,
                specialty: this.specialties.map((specialty) => specialty.id),
                show: this.rootStore.paramStore?.isReferrals
                    ? REFERRALS
                    : SITE,
            };
            if (this.rootStore.healthFundStore.healthFund?.id) {
                urlParams[
                    'health_fund'
                ] = this.rootStore.healthFundStore.healthFund.id;
            }
            const response = await http.get({
                url: '/api/professionals/v1/promoted-profiles/',
                data: urlParams,
            });
            if (response) {
                bannerAds = response.map((d) => snakeToCamelObjectKeys(d));
            }
        } catch (error) {
            this.throwError(`Banner Ad API returned ${error}`);
        } finally {
            const specialties = this.specialties.map((specialty) => {
                const ads = [];
                bannerAds.forEach((ad) => {
                    const BA = ad.specialties.find(
                        (x) => x.id === specialty.id,
                    );
                    if (BA) {
                        ads.push(ad);
                    }
                });
                if (ads.length > 0) {
                    specialty.bannerAds = ads;
                }
                return specialty;
            });
            this.updateStore({
                bannerAdsFetched: true,
                hasBannerAdsOnMultipleSpecialties: bannerAds.length > 0,
                specialties,
            });
        }
    }
}
