import PropTypes from 'prop-types';
import {observer, PropTypes as MobXPropTypes} from 'mobx-react';
import React from 'react';

import analytics from 'analytics.js';
import autobind from 'common/decorators/autobind.js';
import Avatar from 'core/components/Avatar.js';
import BadgeList from 'core/components/BadgeList.js';
import BookNowButton from 'core/components/BookNowButton';
import CollapsibleNumber from 'core/components/CollapsibleNumber.js';
import {
    PROFILE_CONTACT_TYPE,
    PRACTICE_LOC_CONTACT_TYPE,
    CALL_NOW_MODAL_BANNER_ADS,
    CALL_NOW_BUTTON_BANNER_ADS,
} from 'core/constants.js';

import Icon from 'core/components/Icon.js';
import Link from 'core/components/Link.js';
import {openLinkInNewTab} from 'core/utils.js';
import {ProfileStoreContext} from 'core/stores/RootStore.js';

export default class PromotionsBannerAd extends React.Component {
    static contextType = ProfileStoreContext;
    static propTypes = {
        bannerType: PropTypes.string,
        bookingsEnabled: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.string,
        ),
        customBookingDataUrl: PropTypes.string,
        displayPhoneNumber: PropTypes.bool,
        hasMedicalSpecialty: PropTypes.bool.isRequired,
        headline: PropTypes.string.isRequired,
        healthFund: PropTypes.shape({
            id: PropTypes.number,
            showRatings: PropTypes.bool,
        }),
        id: PropTypes.number.isRequired,
        isClient: PropTypes.bool,
        isPractitioner: PropTypes.bool,
        isMobileOrTablet: PropTypes.bool,
        isReferrals: PropTypes.bool,
        offersHicaps: PropTypes.bool,
        offersTelehealth: PropTypes.bool.isRequired,
        practiceData: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({
                address: PropTypes.string,
                id: PropTypes.number,
                localityName: PropTypes.string,
                localityState: PropTypes.string,
                name: PropTypes.string,
                phones: MobXPropTypes.arrayOrObservableArrayOf(
                    PropTypes.shape({
                        number: PropTypes.string,
                    }),
                ).isRequired,
                practiceUrl: PropTypes.string,
            }),
        ),
        ratings: PropTypes.shape({
            average: PropTypes.number.isRequired,
            count: PropTypes.number.isRequired,
        }),
        renderModal: PropTypes.func,
        avatar: PropTypes.string,
        searchAvatar: PropTypes.string,
        specialInterestsTagline: PropTypes.string,
        specialties: PropTypes.oneOfType([
            PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                }),
            ),
            PropTypes.object, // ObservableArray
        ]),
        tagline: PropTypes.string.isRequired,
        tagline2: PropTypes.string.isRequired,
        telehealthDirectory: PropTypes.bool,
        url: PropTypes.string.isRequired,
    };

    static defaultProps = {
        displayPhoneNumber: true,
    };

    getContactId(type) {
        const {id, practitioners} = this.props;
        if (type === PROFILE_CONTACT_TYPE) {
            return practitioners[0].id;
        } else {
            return id;
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            modalDisplayed: false,
        };
        const practiceData = props.practiceData || [];
        const type = props.isPractitioner
            ? PROFILE_CONTACT_TYPE
            : PRACTICE_LOC_CONTACT_TYPE;
        this.modalProps = {
            closeModal: this.closeModal,
            contactId: this.getContactId(type),
            contactName: props.headline,
            contactType: type,
            isEnhanced: true,
            items: practiceData.map((item) => {
                // item will have type if it's a practice list that has a
                // practice group as the first element
                if (!item.type) {
                    item.type = type;
                }
                return item;
            }),
        };
    }

    @autobind
    closeModal() {
        this.setState({modalDisplayed: false});
    }

    @autobind
    handlePhoneClick() {
        this.setState({modalDisplayed: true});
    }

    @autobind
    trackBannerDetailEvent() {
        analytics.track('bannerDetail', {
            'useContent': false,
            'bannerAdId': this.props.id,
        });
    }

    getBookingsParam() {
        const {bannerType, practiceData, practitioners} = this.props;
        const param = {};
        let paramId;
        switch (bannerType) {
            case 'practice_group':
                if (practiceData.length > 0) {
                    paramId = practiceData[0]['id'];
                }
                param['practice_group'] = paramId;
                break;
            case 'practice_location':
                if (practiceData.length > 0) {
                    paramId = practiceData[0]['id'];
                }
                param['practice_location'] = paramId;
                break;
            case 'practitioners':
                if (practitioners.length > 0) {
                    paramId = practitioners[0]['id'];
                }
                param['practitioner'] = paramId;
                break;
        }
        return param;
    }

    getBannerUrl() {
        const {healthFund, url} = this.props;
        let bannerUrl = url;
        if (healthFund?.id) {
            bannerUrl += `?health_fund=${healthFund.id}`;
        }
        return bannerUrl;
    }

    renderSpecialties() {
        const {specialties} = this.props;
        const {isProUser, isReferrals, isClient} =
            this.context.rootStore.paramStore;
        if (!specialties?.length) {
            return null;
        }
        return (
            <h3 data-test-id="specialty-name">
                {specialties
                    .map((specialty) =>
                        isProUser || isReferrals || isClient
                            ? specialty.professionalName
                            : specialty.name,
                    )
                    .join(', ')}
            </h3>
        );
    }

    renderPhoneNumber() {
        const {headline, practiceData} = this.props;
        if (!practiceData || !practiceData[0]?.phones.length) {
            return null;
        }
        const phone = practiceData[0]?.phones[0];
        if (!phone.number) {
            return;
        }
        return (
            <div>
                <dt>
                    <Icon name="phone" />
                    {'Phone:'}
                </dt>
                <dd>
                    <CollapsibleNumber
                        customClass="expanded"
                        number={phone.number}
                        onClick={this.handlePhoneClick}
                        title={`Click to show phone number for ${headline}`}
                    />
                </dd>
            </div>
        );
    }

    renderFees() {
        const {hasMedicalSpecialty, healthFund, id, isPractitioner} =
            this.props;
        const iconColor = healthFund?.colorBrand
            ? {color: `#${healthFund.colorBrand}`}
            : undefined;
        if (isPractitioner && healthFund?.id && hasMedicalSpecialty) {
            return (
                <div>
                    <dt className="fees-upsell" style={iconColor}>
                        <Icon name="feesGraph" />
                        {'Out-of-pocket-cost: '}
                    </dt>
                    <dd>
                        <Link href={`${this.getBannerUrl()}#fees`}>
                            {'See out-of-pocket costs'}
                        </Link>
                    </dd>
                </div>
            );
        }

        return null;
    }

    renderRatings() {
        const {healthFund, isReferrals, ratings} = this.props;
        if (
            isReferrals ||
            !ratings ||
            (healthFund?.id && !healthFund?.showRatings)
        ) {
            return null;
        }

        const {average, count} = ratings;
        return (
            <p className="ratings" data-ratings={count}>
                <Icon name="ratings" />
                <span>
                    {`${count} Rating${count > 1 ? 's' : ''}`}
                    {` (${average} Average)`}
                </span>
            </p>
        );
    }

    renderTaglines() {
        const {isReferrals, tagline, tagline2, specialInterestsTagline} =
            this.props;

        return (
            <div>
                <p>{tagline}</p>
                <p>{tagline2}</p>
                {specialInterestsTagline && (
                    <p>
                        <strong>{'Special interests: '}</strong>
                        {specialInterestsTagline}
                    </p>
                )}
            </div>
        );
    }

    renderBookNowBtn() {
        const {bookingsEnabled, customBookingDataUrl, id} = this.props;

        if (bookingsEnabled?.length > 0) {
            return (
                <BookNowButton
                    objectType="banner ad"
                    objectId={id}
                    params={this.getBookingsParam()}
                />
            );
        } else if (customBookingDataUrl) {
            return (
                <BookNowButton
                    action={() => openLinkInNewTab(customBookingDataUrl)}
                    objectType="banner ad"
                    objectId={id}
                    params={this.getBookingsParam()}
                />
            );
        } else {
            return null;
        }
    }

    renderMainContent() {
        const {
            avatar,
            displayPhoneNumber,
            hasMedicalSpecialty,
            headline,
            healthFund,
            id,
            initials,
            isPractitioner,
            isStaff,
            isMobileOrTablet,
            offersHicaps,
            offersTelehealth,
            searchAvatar,
            telehealthDirectory,
        } = this.props;
        return (
            <>
                <Link
                    action={this.trackBannerDetailEvent}
                    customClass="card-link"
                    href={this.getBannerUrl()}
                >
                    <span>{`${headline} profile link`}</span>
                </Link>
                {isMobileOrTablet && (
                    <Avatar
                        avatar={searchAvatar || avatar}
                        displayName={headline}
                        initials={initials}
                        profileId={id}
                    />
                )}
                <h2>
                    {headline}
                    {isStaff && <Icon name="checkmark" />}{' '}
                    <Link href={this.getBannerUrl()}>
                        {this.props.isPractice
                            ? 'Show practice'
                            : 'Show profile'}
                        <Icon name="arrow" />
                    </Link>
                </h2>
                {this.renderSpecialties()}
                {this.renderRatings()}
                {this.renderTaglines()}
                {((isPractitioner && healthFund?.id && hasMedicalSpecialty) ||
                    displayPhoneNumber) && (
                    <dl className="icon-list">
                        {displayPhoneNumber && this.renderPhoneNumber()}
                        {this.renderFees()}
                    </dl>
                )}
                <BadgeList
                    isMedical={hasMedicalSpecialty}
                    offersHicaps={offersHicaps}
                    offersTelehealth={telehealthDirectory || offersTelehealth}
                />
                {isMobileOrTablet && this.renderBookNowBtn()}
            </>
        );
    }

    renderSidebarContent() {
        const {
            avatar,
            bookingsEnabled,
            customBookingDataUrl,
            headline,
            id,
            initials,
            searchAvatar,
        } = this.props;
        return (
            <>
                <Avatar
                    avatar={searchAvatar || avatar}
                    displayName={headline}
                    initials={initials}
                    profileId={id}
                />
                {bookingsEnabled?.length > 0 && (
                    <BookNowButton
                        objectType="banner ad"
                        objectId={id}
                        params={this.getBookingsParam()}
                    />
                )}
                {!bookingsEnabled?.length && customBookingDataUrl && (
                    <BookNowButton
                        action={() => openLinkInNewTab(customBookingDataUrl)}
                        objectType="banner ad"
                        objectId={id}
                        params={this.getBookingsParam()}
                    />
                )}
            </>
        );
    }

    render() {
        const {isMobileOrTablet, renderModal, telehealthDirectory} =
            this.props;
        return (
            <li
                className={`cell ${
                    telehealthDirectory ? 'telehealth-directory' : ''
                }`.trim()}
                data-ga-selector={CALL_NOW_MODAL_BANNER_ADS}
                data-ga-object-type={CALL_NOW_BUTTON_BANNER_ADS}
            >
                {this.state.modalDisplayed && renderModal(this.modalProps)}
                {isMobileOrTablet ? (
                    this.renderMainContent()
                ) : (
                    <>
                        <div>{this.renderMainContent()}</div>
                        <div>{this.renderSidebarContent()}</div>
                    </>
                )}
            </li>
        );
    }
}
