import {observer} from 'mobx-react';
import React from 'react';

import {PromotionsStoreContext} from 'core/stores/RootStore.js';
import PromotionsBannerAd from 'promotions/components/PromotionsBannerAd.js';
import PromotedProfiles from 'promotions/components/PromotedProfiles.js';
import PromotionsButton, {
    CALL_NOW_MODAL,
} from 'promotions/components/PromotionsButton.js';

export default
@observer
class PromotionsMessage extends React.Component {
    static contextType = PromotionsStoreContext;

    constructor() {
        super();
        this.promotedProfile = React.createRef();
    }

    renderPromotedProfiles() {
        const {
            hasNoPromotions,
            specialties,
            specialtyBannerAds,
            selectedSpecialist,
            isMobile,
            isMobileOrTablet,
            rootStore: {
                paramStore: {isClient, isReferrals},
                healthFundStore: {healthFund},
            },
        } = this.context;
        const profiles = specialtyBannerAds.map((ad, idx) => {
            return {
                id: ad.id,
                avatar: ad.searchAvatar,

                displayName: ad.headline,
                profileUrl: ad.url,
                specialties: ad.specialties,

                initials: ad.initials,
            };
        });
        const hasMedicalSpecialty = !!specialties?.find((sp) => sp.isMedical);
        return (
            <>
                {specialtyBannerAds.length > 0 && (
                    <>
                        <strong className="profile-header">
                            {'Other specialists or practice groups with the same specialty'}
                        </strong>
                        {specialtyBannerAds.length === 1 && (
                            <PromotionsBannerAd
                                {...specialtyBannerAds[0]}
                                displayPhoneNumber={false}
                                hasMedicalSpecialty={hasMedicalSpecialty}
                                healthFund={healthFund}
                                isClient={isClient}
                                isMobileOrTablet={isMobileOrTablet}
                                isReferrals={isReferrals}
                                ref={this.promotedProfile}
                            />
                        )}
                        {specialtyBannerAds.length > 1 && (
                            <PromotedProfiles
                                customClass="promoted-profiles"
                                gapSchemeResultsCount={profiles.length}
                                healthFundId={healthFund?.id}
                                isMobile={isMobile}
                                isMobileOrTablet={isMobileOrTablet}
                                profiles={profiles}
                                showLinktoMorePractitioners={false}
                                specialtyId={selectedSpecialist}
                            />
                        )}
                    </>
                )}
            </>
        );
    }

    renderMessage() {
        let customClass = '';
        if (window.TINTED_CTA_BUTTON) {
            customClass = 'cta secondary';
        }
        return (
            <>
                <p>
                    {
                        'Other specialists or practice groups with the same specialty.'
                    }
                </p>
                <PromotionsButton
                    customClass={customClass}
                    hasBackButton
                    location={CALL_NOW_MODAL}
                />
            </>
        );
    }

    render() {
        const {hasNoPromotions, hidePromotionCTA, specialties} = this.context;
        if (hidePromotionCTA) {
            return null;
        }
        return (
            <footer>
                {hasNoPromotions && specialties.length <= 1
                    ? this.renderPromotedProfiles()
                    : this.renderMessage()}
            </footer>
        );
    }
}
