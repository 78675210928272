import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import autobind from 'common/decorators/autobind.js';
import Button from 'core/components/Button.js';
import Icon from 'core/components/Icon.js';
import {PromotionsStoreContext} from 'core/stores/RootStore.js';
import {MODAL_TYPE_BANNERADS} from 'promotions/stores/PromotionsStore.js';

import {SEE_PRACTITIONER_QUICKLY_BTN} from 'core/constants';
export const CALL_NOW_MODAL = 'call now';
export const PROFILE_PAGE = 'profile page';

export default
@observer
class PromotionsButton extends React.Component {
    static contextType = PromotionsStoreContext;

    static propTypes = {
        hasBackButton: PropTypes.bool,
        location: PropTypes.oneOf([CALL_NOW_MODAL, PROFILE_PAGE]).isRequired,
    };

    static defaultProps = {
        hasBackButton: false,
    };

    @autobind
    handleButton() {
        const {hasBackButton} = this.props;
        this.context.updateStore({hasBackButton});
        this.context.showModal(MODAL_TYPE_BANNERADS);
    }

    render() {
        const {ctaCopy, hidePromotionCTA} = this.context;
        if (hidePromotionCTA) {
            // Hide CTA for the following conditions:
            //   1. No promotions available
            //   2. The I don't have a health fund option has been selected
            //   3. Has a selected health fund that is not participating in any available promotions
            return null;
        }
        return (
            <Button
                action={this.handleButton}
                customClass="cta tertiary offers"
                gaSelector={SEE_PRACTITIONER_QUICKLY_BTN}
                text={
                    <>
                        <strong>{ctaCopy}</strong>
                        <Icon name={'bookmark'} />
                    </>
                }
            />
        );
    }
}
