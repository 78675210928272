import {PropTypes as MobXPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import Link from 'core/components/Link.js';
import PromotedProfileCard from 'professional/components/PromotedProfileCard.js';
import {ProfileStoreContext} from 'core/stores/RootStore.js';

export default class PromotedProfiles extends React.Component {
    static contextType = ProfileStoreContext;
    static propTypes = {
        customClass: PropTypes.string,
        profiles: PropTypes.arrayOf(
            PropTypes.shape({
                avatar: PropTypes.string,
                id: PropTypes.number,
                initials: PropTypes.string.isRequired,
                displayName: PropTypes.string,
                profileUrl: PropTypes.string,
                specialties: MobXPropTypes.arrayOrObservableArrayOf(
                    PropTypes.object,
                ),
            }),
        ).isRequired,
        showLinktoMorePractitioners: PropTypes.bool,
        specialtyId: PropTypes.number,
        version: PropTypes.number,
    };

    static defaultProps = {
        customClass: '',
        showLinktoMorePractitioners: true,
    };

    buildQueryParams(params) {
        return Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');
    }

    getSearchUrl() {
        const {
            healthFundId,
            gapSchemeId,
            localityId,
            specialtyId,
            version,
        } = this.props;

        const params = {
            specialty: specialtyId,
            locality: localityId,
            'gap_scheme': gapSchemeId,
            'health_fund': healthFundId,
        };
        if (version) {
            params.version = version;
        }
        return `/directory/?${this.buildQueryParams(params)}`;
    }

    renderIconRow(profiles) {
        const {isMobileOrTablet} = this.props;
        if (profiles) {
            let reducedProfiles = profiles.slice(2, 5);
            if (isMobileOrTablet) {
                reducedProfiles = profiles.slice(0, 3);
            }
            return reducedProfiles.map((profile, index) => (
                <i
                    className="avatar"
                    key={`${profile.displayName}-avatar${index}`}
                >
                    <img src={profile.avatar} />
                </i>
            ));
        }
        return null;
    }

    renderMorePractitioners(profiles) {
        const {
            gapSchemeId,
            gapSchemeResultsCount,
            isMobileOrTablet,
            localityId,
            specialtyId,
        } = this.props;
        const length = profiles.length;
        if (length > 3 || isMobileOrTablet) {
            return (
                <Link customClass="directory-link" href={this.getSearchUrl()}>
                    {' '}
                    {this.renderIconRow(profiles)}
                    {isMobileOrTablet ? (
                        <span>
                            {'See practitioners offering'}
                            <br />
                            <strong>{'100% no gap options'}</strong>
                        </span>
                    ) : (
                        <span>
                            <h2>
                                {gapSchemeResultsCount > 25
                                    ? '25+ more'
                                    : `+${gapSchemeResultsCount - 2} more`}
                            </h2>
                            <p>{'participating practitioners'}</p>
                        </span>
                    )}
                </Link>
            );
        }
        return null;
    }

    getSpecialty(profile) {
        const {specialtyId} = this.props;
        const specialty = profile.specialties.find(
            (specialty) => specialty.id === specialtyId,
        );
        const {
            isProUser,
            isReferrals,
            isClient,
        } = this.context.rootStore.paramStore;
        const proUser = isProUser || isReferrals || isClient;
        if (specialty) {
            return proUser ? specialty.professionalName : specialty.name;
        }
        return proUser
            ? profile.specialties[0]?.professionalName
            : profile.specialties[0]?.name;
    }

    render() {
        const {
            customClass,
            gapSchemeId,
            healthFundId,
            profiles,
            version,
            showLinktoMorePractitioners,
        } = this.props;
        const promotedProfileCount =
            profiles.length === 3 && !showLinktoMorePractitioners ? 3 : 2;
        const profileUrlParams = {
            'health_fund': healthFundId,
        };
        if (version) {
            profileUrlParams.version = version;
        }
        return (
            <nav className={customClass}>
                {profiles.map((profile, index) => {
                    const promotedProps = {
                        avatar: profile.avatar,
                        cardNumber: index,
                        displayName: profile.displayName,
                        gapSchemeId,
                        id: profile.id,
                        initials: profile.initials,
                        profileUrl: `${
                            profile.profileUrl
                        }?${this.buildQueryParams(profileUrlParams)}`,
                        specialtyName: this.getSpecialty(profile),
                    };

                    if (index < promotedProfileCount) {
                        return (
                            <PromotedProfileCard
                                {...promotedProps}
                                key={`${profile.displayName}${index}`}
                            />
                        );
                    }
                })}
                {showLinktoMorePractitioners &&
                    this.renderMorePractitioners(profiles)}
            </nav>
        );
    }
}
